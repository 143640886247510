:root {
  --ifm-dark-value: 10%;
  --ifm-darker-value: 15%;
  --ifm-darkest-value: 30%;
  --ifm-light-value: 15%;
  --ifm-lighter-value: 30%;
  --ifm-lightest-value: 50%;
  --ifm-color-primary: #3578e5;
  --ifm-color-primary-dark: rgb(48, 108, 206);
  --ifm-color-primary-darker: rgb(45, 102, 195);
  --ifm-color-primary-darkest: rgb(37, 84, 160);
  --ifm-color-primary-light: rgb(83, 140, 233);
  --ifm-color-primary-lighter: rgb(114, 161, 237);
  --ifm-color-primary-lightest: rgb(154, 188, 242);

  --ifm-color-secondary: #ebedf0;
  --ifm-color-secondary-dark: rgb(212, 213, 216);
  --ifm-color-secondary-darker: rgb(200, 201, 204);
  --ifm-color-secondary-darkest: rgb(164, 166, 168);
  --ifm-color-secondary-light: rgb(238, 240, 242);
  --ifm-color-secondary-lighter: rgb(241, 242, 245);
  --ifm-color-secondary-lightest: rgb(245, 246, 248);

  --ifm-color-success: #00a400;
  --ifm-color-success-dark: rgb(0, 148, 0);
  --ifm-color-success-darker: rgb(0, 139, 0);
  --ifm-color-success-darkest: rgb(0, 115, 0);
  --ifm-color-success-light: rgb(38, 178, 38);
  --ifm-color-success-lighter: rgb(77, 191, 77);
  --ifm-color-success-lightest: rgb(128, 210, 128);

  --ifm-color-info: #54c7ec;
  --ifm-color-info-dark: rgb(76, 179, 212);
  --ifm-color-info-darker: rgb(71, 169, 201);
  --ifm-color-info-darkest: rgb(59, 139, 165);
  --ifm-color-info-light: rgb(110, 207, 239);
  --ifm-color-info-lighter: rgb(135, 216, 242);
  --ifm-color-info-lightest: rgb(170, 227, 246);

  --ifm-color-warning: #ffba00;
  --ifm-color-warning-dark: rgb(230, 167, 0);
  --ifm-color-warning-darker: rgb(217, 158, 0);
  --ifm-color-warning-darkest: rgb(179, 130, 0);
  --ifm-color-warning-light: rgb(255, 196, 38);
  --ifm-color-warning-lighter: rgb(255, 207, 77);
  --ifm-color-warning-lightest: rgb(255, 221, 128);

  --ifm-color-danger: #fa383e;
  --ifm-color-danger-dark: rgb(225, 50, 56);
  --ifm-color-danger-darker: rgb(213, 48, 53);
  --ifm-color-danger-darkest: rgb(175, 39, 43);
  --ifm-color-danger-light: rgb(251, 86, 91);
  --ifm-color-danger-lighter: rgb(251, 116, 120);
  --ifm-color-danger-lightest: rgb(253, 156, 159);

  --ifm-color-white: #fff;
  --ifm-color-black: #000;

  --ifm-color-gray-0: var(--ifm-color-white);
  --ifm-color-gray-100: #f5f6f7;
  --ifm-color-gray-200: #ebedf0;
  --ifm-color-gray-300: #dadde1;
  --ifm-color-gray-400: #ccd0d5;
  --ifm-color-gray-500: #bec3c9;
  --ifm-color-gray-600: #8d949e;
  --ifm-color-gray-700: #606770;
  --ifm-color-gray-800: #444950;
  --ifm-color-gray-900: #1c1e21;
  --ifm-color-gray-1000: var(--ifm-color-black);

  --ifm-color-emphasis-0: var(--ifm-color-gray-0);
  --ifm-color-emphasis-100: var(--ifm-color-gray-100);
  --ifm-color-emphasis-200: var(--ifm-color-gray-200);
  --ifm-color-emphasis-300: var(--ifm-color-gray-300);
  --ifm-color-emphasis-400: var(--ifm-color-gray-400);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-600);
  --ifm-color-emphasis-700: var(--ifm-color-gray-700);
  --ifm-color-emphasis-800: var(--ifm-color-gray-800);
  --ifm-color-emphasis-900: var(--ifm-color-gray-900);
  --ifm-color-emphasis-1000: var(--ifm-color-gray-1000);
  --ifm-color-content: var(--ifm-color-emphasis-900);
  --ifm-color-content-inverse: var(--ifm-color-emphasis-0);
  --ifm-color-content-secondary: #606770;

  --ifm-background-color: transparent;
  --ifm-background-surface-color: var(--ifm-color-content-inverse);
  --ifm-global-border-width: 1px;
  --ifm-global-radius: 0.4rem;

  --ifm-hover-overlay: rgba(0, 0, 0, 0.05);
  --ifm-font-color-base: var(--ifm-color-content);
  --ifm-font-color-base-inverse: var(--ifm-color-content-inverse);
  --ifm-font-color-secondary: var(--ifm-color-content-secondary);
  --ifm-font-family-base: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --ifm-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --ifm-font-size-base: 100%;

  --ifm-font-weight-light: 300;
  --ifm-font-weight-normal: 400;
  --ifm-font-weight-semibold: 500;
  --ifm-font-weight-bold: 700;

  --ifm-font-weight-base: var(--ifm-font-weight-normal);
  --ifm-line-height-base: 1.65;
  --ifm-global-spacing: 1rem;
  --ifm-spacing-vertical: var(--ifm-global-spacing);
  --ifm-spacing-horizontal: var(--ifm-global-spacing);
  --ifm-transition-fast: 200ms;
  --ifm-transition-slow: 400ms;
  --ifm-global-shadow-lw: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --ifm-global-shadow-md: 0 5px 40px rgba(0, 0, 0, 0.2);
  --ifm-global-shadow-tl: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --ifm-z-index-dropdown: 100;
  --ifm-z-index-fixed: 200;
  --ifm-z-index-overlay: 400;
}
* {
  box-sizing: border-box;
}
html {
  background-color: var(--ifm-background-color);
  box-sizing: border-box;
  color: var(--ifm-font-color-base);
  line-height: var(--ifm-line-height-base);
  font-family: var(--ifm-font-family-base);
  font-size: var(--ifm-font-size-base);
  text-rendering: optimizelegibility;

  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  word-wrap: break-word;
}
:root {
  --ifm-container-width: 1140px;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--ifm-container-width);
  padding-left: var(--ifm-spacing-horizontal);
  padding-right: var(--ifm-spacing-horizontal);
  width: 100%;
}
.container--fluid {
    max-width: inherit;
  }
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: calc(var(--ifm-spacing-horizontal) * -1);
  margin-right: calc(var(--ifm-spacing-horizontal) * -1);
}
.row--no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
.row--no-gutters > .col {
      padding-left: 0;
      padding-right: 0;
    }
.row--align-top {
    align-items: flex-start;
  }
.row--align-bottom {
    align-items: flex-end;
  }
.row--align-center {
    align-items: center;
  }
.row--align-stretch {
    align-items: stretch;
  }
.row--align-baseline {
    align-items: baseline;
  }
.row .col {
    --ifm-col-width: 100%;

    display: block;
    flex: 1 0;
    margin-left: 0;
    max-width: 100%;
    padding-left: var(--ifm-spacing-horizontal);
    padding-right: var(--ifm-spacing-horizontal);
    width: 100%;
  }
.row .col[class*='col--'] {
      flex: 0 0 var(--ifm-col-width);
      max-width: var(--ifm-col-width);
    }
@media (max-width: 996px) {
      .row .col.col.col {
        --ifm-col-width: 100%;
        flex-basis: var(--ifm-col-width);
        margin-left: 0;

        max-width: var(--ifm-col-width);
      }
    }
.row .col.col--1 {
        --ifm-col-width: calc(1 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-1 {
        margin-left: calc(1 / 12 * 100%);
      }
.row .col.col--2 {
        --ifm-col-width: calc(2 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-2 {
        margin-left: calc(2 / 12 * 100%);
      }
.row .col.col--3 {
        --ifm-col-width: calc(3 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-3 {
        margin-left: calc(3 / 12 * 100%);
      }
.row .col.col--4 {
        --ifm-col-width: calc(4 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-4 {
        margin-left: calc(4 / 12 * 100%);
      }
.row .col.col--5 {
        --ifm-col-width: calc(5 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-5 {
        margin-left: calc(5 / 12 * 100%);
      }
.row .col.col--6 {
        --ifm-col-width: calc(6 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-6 {
        margin-left: calc(6 / 12 * 100%);
      }
.row .col.col--7 {
        --ifm-col-width: calc(7 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-7 {
        margin-left: calc(7 / 12 * 100%);
      }
.row .col.col--8 {
        --ifm-col-width: calc(8 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-8 {
        margin-left: calc(8 / 12 * 100%);
      }
.row .col.col--9 {
        --ifm-col-width: calc(9 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-9 {
        margin-left: calc(9 / 12 * 100%);
      }
.row .col.col--10 {
        --ifm-col-width: calc(10 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-10 {
        margin-left: calc(10 / 12 * 100%);
      }
.row .col.col--11 {
        --ifm-col-width: calc(11 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-11 {
        margin-left: calc(11 / 12 * 100%);
      }
.row .col.col--12 {
        --ifm-col-width: calc(12 / 12 * 100%);

        flex: 0 0 var(--ifm-col-width);
        max-width: var(--ifm-col-width);
      }
.row .col.col--offset-12 {
        margin-left: calc(12 / 12 * 100%);
      }
.margin--none {
      margin: 0 !important;
    }
.margin-top--none {
        margin-top: 0 !important;
      }
.margin-left--none {
        margin-left: 0 !important;
      }
.margin-bottom--none {
        margin-bottom: 0 !important;
      }
.margin-right--none {
        margin-right: 0 !important;
      }
.margin-vert--none {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
.margin-horiz--none {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
.margin--xs {
      margin: 0.25rem !important;
    }
.margin-top--xs {
        margin-top: 0.25rem !important;
      }
.margin-left--xs {
        margin-left: 0.25rem !important;
      }
.margin-bottom--xs {
        margin-bottom: 0.25rem !important;
      }
.margin-right--xs {
        margin-right: 0.25rem !important;
      }
.margin-vert--xs {
      margin-bottom: 0.25rem !important;
      margin-top: 0.25rem !important;
    }
.margin-horiz--xs {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
.margin--sm {
      margin: 0.5rem !important;
    }
.margin-top--sm {
        margin-top: 0.5rem !important;
      }
.margin-left--sm {
        margin-left: 0.5rem !important;
      }
.margin-bottom--sm {
        margin-bottom: 0.5rem !important;
      }
.margin-right--sm {
        margin-right: 0.5rem !important;
      }
.margin-vert--sm {
      margin-bottom: 0.5rem !important;
      margin-top: 0.5rem !important;
    }
.margin-horiz--sm {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
.margin--md {
      margin: 1rem !important;
    }
.margin-top--md {
        margin-top: 1rem !important;
      }
.margin-left--md {
        margin-left: 1rem !important;
      }
.margin-bottom--md {
        margin-bottom: 1rem !important;
      }
.margin-right--md {
        margin-right: 1rem !important;
      }
.margin-vert--md {
      margin-bottom: 1rem !important;
      margin-top: 1rem !important;
    }
.margin-horiz--md {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
.margin--lg {
      margin: 2rem !important;
    }
.margin-top--lg {
        margin-top: 2rem !important;
      }
.margin-left--lg {
        margin-left: 2rem !important;
      }
.margin-bottom--lg {
        margin-bottom: 2rem !important;
      }
.margin-right--lg {
        margin-right: 2rem !important;
      }
.margin-vert--lg {
      margin-bottom: 2rem !important;
      margin-top: 2rem !important;
    }
.margin-horiz--lg {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
.margin--xl {
      margin: 5rem !important;
    }
.margin-top--xl {
        margin-top: 5rem !important;
      }
.margin-left--xl {
        margin-left: 5rem !important;
      }
.margin-bottom--xl {
        margin-bottom: 5rem !important;
      }
.margin-right--xl {
        margin-right: 5rem !important;
      }
.margin-vert--xl {
      margin-bottom: 5rem !important;
      margin-top: 5rem !important;
    }
.margin-horiz--xl {
      margin-left: 5rem !important;
      margin-right: 5rem !important;
    }
.padding--none {
      padding: 0 !important;
    }
.padding-top--none {
        padding-top: 0 !important;
      }
.padding-left--none {
        padding-left: 0 !important;
      }
.padding-bottom--none {
        padding-bottom: 0 !important;
      }
.padding-right--none {
        padding-right: 0 !important;
      }
.padding-vert--none {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
.padding-horiz--none {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
.padding--xs {
      padding: 0.25rem !important;
    }
.padding-top--xs {
        padding-top: 0.25rem !important;
      }
.padding-left--xs {
        padding-left: 0.25rem !important;
      }
.padding-bottom--xs {
        padding-bottom: 0.25rem !important;
      }
.padding-right--xs {
        padding-right: 0.25rem !important;
      }
.padding-vert--xs {
      padding-bottom: 0.25rem !important;
      padding-top: 0.25rem !important;
    }
.padding-horiz--xs {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
.padding--sm {
      padding: 0.5rem !important;
    }
.padding-top--sm {
        padding-top: 0.5rem !important;
      }
.padding-left--sm {
        padding-left: 0.5rem !important;
      }
.padding-bottom--sm {
        padding-bottom: 0.5rem !important;
      }
.padding-right--sm {
        padding-right: 0.5rem !important;
      }
.padding-vert--sm {
      padding-bottom: 0.5rem !important;
      padding-top: 0.5rem !important;
    }
.padding-horiz--sm {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
.padding--md {
      padding: 1rem !important;
    }
.padding-top--md {
        padding-top: 1rem !important;
      }
.padding-left--md {
        padding-left: 1rem !important;
      }
.padding-bottom--md {
        padding-bottom: 1rem !important;
      }
.padding-right--md {
        padding-right: 1rem !important;
      }
.padding-vert--md {
      padding-bottom: 1rem !important;
      padding-top: 1rem !important;
    }
.padding-horiz--md {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
.padding--lg {
      padding: 2rem !important;
    }
.padding-top--lg {
        padding-top: 2rem !important;
      }
.padding-left--lg {
        padding-left: 2rem !important;
      }
.padding-bottom--lg {
        padding-bottom: 2rem !important;
      }
.padding-right--lg {
        padding-right: 2rem !important;
      }
.padding-vert--lg {
      padding-bottom: 2rem !important;
      padding-top: 2rem !important;
    }
.padding-horiz--lg {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
.padding--xl {
      padding: 5rem !important;
    }
.padding-top--xl {
        padding-top: 5rem !important;
      }
.padding-left--xl {
        padding-left: 5rem !important;
      }
.padding-bottom--xl {
        padding-bottom: 5rem !important;
      }
.padding-right--xl {
        padding-right: 5rem !important;
      }
.padding-vert--xl {
      padding-bottom: 5rem !important;
      padding-top: 5rem !important;
    }
.padding-horiz--xl {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
:root {
  --ifm-code-background: var(--ifm-color-emphasis-200);
  --ifm-code-border-radius: var(--ifm-global-radius);
  --ifm-code-color: var(--ifm-color-emphasis-900);
  --ifm-code-font-size: 90%;
  --ifm-code-padding-horizontal: 0.4rem;
  --ifm-code-padding-vertical: 0.2rem;

  --ifm-pre-background: var(--ifm-color-emphasis-100);
  --ifm-pre-border-radius: var(--ifm-code-border-radius);
  --ifm-pre-color: inherit;
  --ifm-pre-line-height: 1.45;
  --ifm-pre-padding: 1rem;
}
code {
  background-color: var(--ifm-code-background);
  border-radius: var(--ifm-code-border-radius);
  color: var(--ifm-code-color);
  font-family: var(--ifm-font-family-monospace);
  font-size: var(--ifm-code-font-size);
  margin: 0;
  padding: var(--ifm-code-padding-vertical) var(--ifm-code-padding-horizontal);
}
a code {
  color: inherit;
}
pre {
  background-color: var(--ifm-pre-background);
  border-radius: var(--ifm-pre-border-radius);
  color: var(--ifm-pre-color);
  font-family: var(--ifm-font-family-monospace);
  font-size: var(--ifm-code-font-size);
  line-height: var(--ifm-pre-line-height);
  margin-bottom: var(--ifm-spacing-vertical);
  margin-top: 0;
  overflow: auto;
  padding: var(--ifm-pre-padding);
  word-wrap: normal;
}
pre code {
    background-color: transparent;
    border: 0;
    display: inline;
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    max-width: auto;
    overflow: visible;
    padding: 0;
    white-space: pre;
    word-break: normal;
    word-wrap: normal;
  }
kbd {
  background-color: var(--ifm-color-emphasis-0);
  border: 1px solid var(--ifm-color-emphasis-400);
  border-radius: 0.2rem;
  box-shadow: inset 0 -1px 0 var(--ifm-color-emphasis-400);
  color: var(--ifm-color-emphasis-800);
  font: 80% var(--ifm-font-family-monospace);
  padding: 0.15rem 0.3rem;
}
:root {
  --ifm-heading-color: inherit;
  --ifm-heading-margin-top: 0;
  --ifm-heading-margin-bottom: var(--ifm-spacing-vertical);
  --ifm-heading-font-family: inherit;
  --ifm-heading-font-weight: var(--ifm-font-weight-bold);
  --ifm-heading-line-height: 1.25;

  --ifm-h1-font-size: 2rem;
  --ifm-h2-font-size: 1.5rem;
  --ifm-h3-font-size: 1.25rem;
  --ifm-h4-font-size: 1rem;
  --ifm-h5-font-size: 0.875rem;
  --ifm-h6-font-size: 0.85rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ifm-heading-color);
  font-weight: var(--ifm-heading-font-weight);
  line-height: var(--ifm-heading-line-height);
  margin-bottom: var(--ifm-heading-margin-bottom);
  margin-top: var(--ifm-heading-margin-top);
}
h1 {
    font-size: var(--ifm-h1-font-size);
  }
h2 {
    font-size: var(--ifm-h2-font-size);
  }
h3 {
    font-size: var(--ifm-h3-font-size);
  }
h4 {
    font-size: var(--ifm-h4-font-size);
  }
h5 {
    font-size: var(--ifm-h5-font-size);
  }
h6 {
    font-size: var(--ifm-h6-font-size);
  }
:root {
  --ifm-image-alignment-padding: 1.25rem;
}
img {
  border-style: none;
  box-sizing: content-box;
  max-width: 100%;
}
img[align='right'] {
  padding-left: var(--image-alignment-padding);
}
img[align='left'] {
  padding-right: var(--image-alignment-padding);
}
:root {
  --ifm-leading-desktop: 1.25;
  --ifm-leading: calc(var(--ifm-leading-desktop) * 1rem);
}
.markdown {
  --ifm-h1-vertical-rhythm-top: 3;
  --ifm-h2-vertical-rhythm-top: 2;
  --ifm-h3-vertical-rhythm-top: 1.5;
  --ifm-heading-vertical-rhythm-top: 1.25;

  --ifm-h1-vertical-rhythm-bottom: 1.25;
  --ifm-heading-vertical-rhythm-bottom: 1;
}
.markdown:before {
    content: '';
    display: table;
  }
.markdown:after {
    clear: both;
    content: '';
    display: table;
  }
.markdown > *:first-child {
    margin-top: 0 !important;
  }
.markdown > *:last-child {
    margin-bottom: 0 !important;
  }
.markdown > h1 {
    --ifm-h1-font-size: 3rem;

    margin-bottom: calc(
      var(--ifm-h1-vertical-rhythm-bottom) * var(--ifm-leading)
    );
    margin-top: calc(var(--ifm-h1-vertical-rhythm-top) * var(--ifm-leading));
  }
.markdown > h2 {
    --ifm-h2-font-size: 2rem;

    margin-bottom: calc(
      var(--ifm-heading-vertical-rhythm-bottom) * var(--ifm-leading)
    );
    margin-top: calc(var(--ifm-h2-vertical-rhythm-top) * var(--ifm-leading));
  }
.markdown > h3 {
    --ifm-h3-font-size: 1.5rem;

    margin-bottom: calc(
      var(--ifm-heading-vertical-rhythm-bottom) * var(--ifm-leading)
    );
    margin-top: calc(var(--ifm-h3-vertical-rhythm-top) * var(--ifm-leading));
  }
.markdown > h4,
  .markdown > h5,
  .markdown > h6 {
    margin-bottom: calc(
      var(--ifm-heading-vertical-rhythm-bottom) * var(--ifm-leading)
    );
    margin-top: calc(
      var(--ifm-heading-vertical-rhythm-top) * var(--ifm-leading)
    );
  }
.markdown > pre,
  .markdown > ul,
  .markdown > p {
    margin-bottom: var(--ifm-leading);
  }
.markdown li {
    word-wrap: break-all;
  }
.markdown li > p {
      margin-top: var(--ifm-list-paragraph-margin);
    }
.markdown li + li {
      margin-top: var(--ifm-list-item-margin);
    }
:root {
  --ifm-list-left-padding: 2rem;
  --ifm-list-margin: 1rem;
  --ifm-list-item-margin: 0.25rem;
  --ifm-list-paragraph-margin: 1rem;
}
ul,
ol {
  margin-bottom: var(--ifm-list-margin);
  margin-top: 0;
  padding-left: var(--ifm-list-left-padding);
}
ol ol,
ul ol {
  list-style-type: lower-roman;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
  margin-top: 0;
}
ul ul ol,
ul ol ol,
ol ul ol,
ol ol ol {
  list-style-type: lower-alpha;
}
:root {
  --ifm-table-cell-padding: 0.75rem;

  --ifm-table-background: transparent;
  --ifm-table-stripe-background: var(--ifm-color-emphasis-100);

  --ifm-table-border-width: 1px;
  --ifm-table-border-color: var(--ifm-color-emphasis-300);

  --ifm-table-head-background: inherit;
  --ifm-table-head-color: inherit;
  --ifm-table-head-font-weight: var(--ifm-font-weight-bold);

  --ifm-table-cell-color: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  margin-bottom: var(--ifm-spacing-vertical);
  margin-top: 0;
  overflow: auto;
  width: 100%;
}
table tr {
    background-color: var(--ifm-table-background);
    border-top: var(--ifm-table-border-width) solid
      var(--ifm-table-border-color);
  }
table tr:nth-child(2n) {
    background-color: var(--ifm-table-stripe-background);
  }
table th,
  table td {
    border: var(--ifm-table-border-width) solid var(--ifm-table-border-color);
    padding: var(--ifm-table-cell-padding);
  }
table th {
    background-color: var(--ifm-table-head-background);
    color: var(--ifm-table-head-color);
    font-weight: var(--ifm-table-head-font-weight);
  }
table td {
    color: var(--ifm-table-cell-color);
  }
:root {
  --ifm-link-color: var(--ifm-color-primary);
  --ifm-link-decoration: none;
  --ifm-link-hover-color: var(--ifm-link-color);
  --ifm-link-hover-decoration: underline;
  --ifm-paragraph-margin-bottom: var(--ifm-leading);
  --ifm-blockquote-color: #6a737d;
  --ifm-blockquote-font-size: var(--ifm-font-size-base);
  --ifm-blockquote-padding-horizontal: 1rem;
  --ifm-blockquote-padding-vertical: 1rem;
  --ifm-hr-border-color: var(--ifm-color-emphasis-500);
  --ifm-hr-border-width: 1px;
  --ifm-hr-margin-vertical: 1.5rem;
}
strong {
  font-weight: var(--ifm-font-weight-bold);
}
a {
  color: var(--ifm-link-color);
  -webkit-text-decoration: var(--ifm-link-decoration);
          text-decoration: var(--ifm-link-decoration);
}
a:hover {
    color: var(--ifm-link-hover-color);
    -webkit-text-decoration: var(--ifm-link-hover-decoration);
            text-decoration: var(--ifm-link-hover-decoration);
  }
a:active,
  a:hover {
    outline-width: 0;
  }
a:not([href]) {
    text-decoration: none;
  }
p {
  margin-top: 0;
  margin-bottom: var(--ifm-paragraph-margin-bottom);
}
blockquote {
  border-left: 6px solid var(--ifm-color-emphasis-300);
  color: var(--ifm-blockquote-color);
  font-size: var(--ifm-blockquote-font-size);
  margin: 0;
  margin-bottom: var(--ifm-spacing-vertical);
  padding: var(--ifm-blockquote-padding-vertical)
    var(--ifm-blockquote-padding-horizontal);
}
blockquote > :first-child {
    margin-top: 0;
  }
blockquote > :last-child {
    margin-bottom: 0;
  }
hr {
  border-color: var(--ifm-hr-border-color);
  border-style: solid;
  border-width: var(--ifm-hr-border-width);
  box-sizing: content-box;
  margin: var(--ifm-hr-margin-vertical) 0;
  overflow: hidden;
  padding: 0;
}
hr:before {
    content: '';
    display: table;
  }
hr:after {
    clear: both;
    content: '';
    display: table;
  }
:root {
  --ifm-alert-background-color: inherit;
  --ifm-alert-border-color: inherit;
  --ifm-alert-border-radius: var(--ifm-global-radius);
  --ifm-alert-border-width: var(--ifm-global-border-width);
  --ifm-alert-color: var(--ifm-font-color-base-inverse);
  --ifm-alert-padding-horizontal: var(--ifm-spacing-horizontal);
  --ifm-alert-padding-vertical: var(--ifm-spacing-vertical);
}
.alert {
  background-color: var(--ifm-alert-background-color);
  border-color: var(--ifm-alert-border-color);
  border-style: solid;
  border-width: var(--ifm-alert-border-width);
  border-radius: var(--ifm-alert-border-radius);
  box-sizing: border-box;
  color: var(--ifm-alert-color);
  padding: var(--ifm-alert-padding-vertical) var(--ifm-alert-padding-horizontal);
}
.alert--primary {
      --ifm-alert-background-color: var(--ifm-color-primary);
      --ifm-alert-border-color: var(--ifm-color-primary);
    }
.alert--secondary {
      --ifm-alert-background-color: var(--ifm-color-secondary);
      --ifm-alert-border-color: var(--ifm-color-secondary);
    }
.alert--success {
      --ifm-alert-background-color: var(--ifm-color-success);
      --ifm-alert-border-color: var(--ifm-color-success);
    }
.alert--info {
      --ifm-alert-background-color: var(--ifm-color-info);
      --ifm-alert-border-color: var(--ifm-color-info);
    }
.alert--warning {
      --ifm-alert-background-color: var(--ifm-color-warning);
      --ifm-alert-border-color: var(--ifm-color-warning);
    }
.alert--danger {
      --ifm-alert-background-color: var(--ifm-color-danger);
      --ifm-alert-border-color: var(--ifm-color-danger);
    }
.alert a {
    color: var(--ifm-alert-color);
    text-decoration: underline;
  }
.alert--secondary {
    --ifm-alert-color: var(--ifm-color-gray-900);
  }
.alert .close {
    margin-right: calc(var(--ifm-alert-padding-horizontal) * -1);
    margin-top: calc(var(--ifm-alert-padding-vertical) * -1);
  }
:root {
  --ifm-avatar-intro-margin: 1rem;
  --ifm-avatar-intro-alignment: inherit;
  --ifm-avatar-photo-size-sm: 2rem;
  --ifm-avatar-photo-size-md: 3rem;
  --ifm-avatar-photo-size-lg: 4rem;
  --ifm-avatar-photo-size-xl: 6rem;
}
.avatar {
  display: flex;
}
.avatar__photo-link {
    display: block;
  }
.avatar__photo {
    overflow: hidden;
    border-radius: 50%;
    height: var(--ifm-avatar-photo-size-md);
    width: var(--ifm-avatar-photo-size-md);
  }
.avatar__photo--sm {
      height: var(--ifm-avatar-photo-size-sm);
      width: var(--ifm-avatar-photo-size-sm);
    }
.avatar__photo--lg {
      height: var(--ifm-avatar-photo-size-lg);
      width: var(--ifm-avatar-photo-size-lg);
    }
.avatar__photo--xl {
      height: var(--ifm-avatar-photo-size-xl);
      width: var(--ifm-avatar-photo-size-xl);
    }
.avatar__photo + .avatar__intro {
      margin-left: var(--ifm-avatar-intro-margin);
    }
.avatar__intro {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    text-align: var(--ifm-avatar-intro-alignment);
  }
.avatar__name {
    margin: 0;
  }
.avatar__subtitle {
    margin-top: 0.25rem;
  }
.avatar--vertical {
    --ifm-avatar-intro-alignment: center;
    --ifm-avatar-intro-margin: 0.5rem;

    align-items: center;
    flex-direction: column;
  }
.avatar--vertical .avatar__intro {
      margin-left: 0;
    }
:root {
  --ifm-badge-background-color: inherit;
  --ifm-badge-border-color: inherit;
  --ifm-badge-border-radius: var(--ifm-global-radius);
  --ifm-badge-border-width: var(--ifm-global-border-width);
  --ifm-badge-color: var(--ifm-color-white);
  --ifm-badge-padding-horizontal: calc(var(--ifm-spacing-horizontal) * 0.5);
  --ifm-badge-padding-vertical: calc(var(--ifm-spacing-vertical) * 0.25);
}
.badge {
  background-color: var(--ifm-badge-background-color);
  border-color: var(--ifm-badge-border-color);
  border-style: solid;
  border-width: var(--ifm-badge-border-width);
  border-radius: var(--ifm-badge-border-radius);
  box-sizing: border-box;
  color: var(--ifm-badge-color);
  display: inline-block;
  font-size: 75%;
  font-weight: var(--ifm-font-weight-bold);
  line-height: 1;
  padding: var(--ifm-badge-padding-vertical) var(--ifm-badge-padding-horizontal);
  vertical-align: baseline;
}
.badge--primary {
      --ifm-badge-background-color: var(--ifm-color-primary);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--secondary {
      --ifm-badge-background-color: var(--ifm-color-secondary);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--success {
      --ifm-badge-background-color: var(--ifm-color-success);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--info {
      --ifm-badge-background-color: var(--ifm-color-info);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--warning {
      --ifm-badge-background-color: var(--ifm-color-warning);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--danger {
      --ifm-badge-background-color: var(--ifm-color-danger);
      --ifm-badge-border-color: var(--ifm-badge-background-color);
    }
.badge--secondary {
    color: var(--ifm-color-black);
  }
:root {
  --ifm-breadcrumb-border-radius: 1.5rem;
  --ifm-breadcrumb-spacing: 0.0625rem;
  --ifm-breadcrumb-color-active: var(--ifm-color-primary);
  --ifm-breadcrumb-item-background-active: var(--ifm-hover-overlay);
  --ifm-breadcrumb-padding-horizontal: 1rem;
  --ifm-breadcrumb-padding-vertical: 0.5rem;
  --ifm-breadcrumb-size-multiplier: 1;
  --ifm-breadcrumb-separator: url('data:image/svg+xml;utf8,<svg alt="Chevron Right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve"><g><g><polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>');
  --ifm-breadcrumb-separator-size: 0.5rem;
  --ifm-breadcrumb-separator-size-multiplier: 1.25;
}
.breadcrumbs {
  margin-bottom: 0;
  padding-left: 0;
}
.breadcrumbs__item {
    display: inline-block;
    list-style-type: none;
  }
.breadcrumbs__item:not(:first-child) {
      margin-left: var(--ifm-breadcrumb-spacing);
    }
.breadcrumbs__item:not(:last-child) {
      margin-right: var(--ifm-breadcrumb-spacing);
    }
.breadcrumbs__item:not(:last-child):after {
        background-image: var(--ifm-breadcrumb-separator);
        background-position: center;
        content: ' ';
        display: inline-block;
        height: calc(
          var(--ifm-breadcrumb-separator-size) *
            var(--ifm-breadcrumb-size-multiplier) *
            var(--ifm-breadcrumb-separator-size-multiplier)
        );
        margin: 0 0.5rem;
        opacity: 0.5;
        width: calc(
          var(--ifm-breadcrumb-separator-size) *
            var(--ifm-breadcrumb-size-multiplier) *
            var(--ifm-breadcrumb-separator-size-multiplier)
        );
      }
.breadcrumbs__item--active .breadcrumbs__link {
        background: var(--ifm-breadcrumb-item-background-active);
        color: var(--ifm-breadcrumb-color-active);
      }
.breadcrumbs__item:not(.breadcrumbs__item--active):hover .breadcrumbs__link {
        background: var(--ifm-breadcrumb-item-background-active);
        transition: background var(--ifm-transition-fast)
          cubic-bezier(0.08, 0.52, 0.52, 1);
      }
.breadcrumbs__link {
    border-radius: var(--ifm-breadcrumb-border-radius);
    color: var(--ifm-font-color-base);
    display: inline-block;
    font-size: calc(1rem * var(--ifm-breadcrumb-size-multiplier));
    padding: calc(
        var(--ifm-breadcrumb-padding-vertical) *
          var(--ifm-breadcrumb-size-multiplier)
      )
      calc(
        var(--ifm-breadcrumb-padding-horizontal) *
          var(--ifm-breadcrumb-size-multiplier)
      );
  }
.breadcrumbs__link:hover {
      text-decoration: none;
    }
.breadcrumbs--sm {
    --ifm-breadcrumb-size-multiplier: 0.8;
  }
.breadcrumbs--lg {
    --ifm-breadcrumb-size-multiplier: 1.2;
  }
:root {
  --ifm-button-background-color: inherit;
  --ifm-button-border-color: var(--ifm-button-background-color);
  --ifm-button-border-width: var(--ifm-global-border-width);
  --ifm-button-color: var(--ifm-font-color-base-inverse);
  --ifm-button-font-weight: var(--ifm-font-weight-bold);
  --ifm-button-padding-horizontal: 1.5rem;
  --ifm-button-padding-vertical: 0.375rem;
  --ifm-button-size-multiplier: 1;
  --ifm-button-transition-duration: var(--ifm-transition-fast);
  --ifm-button-border-radius: calc(
    var(--ifm-global-radius) * var(--ifm-button-size-multiplier)
  );
}
.button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: var(--ifm-button-background-color);
  border-color: var(--ifm-button-border-color);
  border-style: solid;
  border-width: var(--ifm-button-border-width);
  border-radius: var(--ifm-button-border-radius);
  box-sizing: border-box;
  color: var(--ifm-button-color);
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.875rem * var(--ifm-button-size-multiplier));
  font-weight: var(--ifm-button-font-weight);
  line-height: 1.5;
  outline: 0;
  padding: calc(
      var(--ifm-button-padding-vertical) * var(--ifm-button-size-multiplier)
    )
    calc(
      var(--ifm-button-padding-horizontal) * var(--ifm-button-size-multiplier)
    );
  text-align: center;
  text-decoration: none;
  transition: color var(--ifm-button-transition-duration)
      cubic-bezier(0.08, 0.52, 0.52, 1),
    background var(--ifm-button-transition-duration)
      cubic-bezier(0.08, 0.52, 0.52, 1),
    border-color var(--ifm-button-transition-duration)
      cubic-bezier(0.08, 0.52, 0.52, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.button:hover {
    color: var(--ifm-button-color);
    outline: 0;
    text-decoration: none;
  }
.button:focus {
    outline: 0;
  }
.button--outline {
    --ifm-button-background-color: transparent;
    --ifm-button-color: var(--ifm-button-border-color);
  }
.button--outline:hover {
      --ifm-button-background-color: var(--ifm-button-border-color);
    }
.button--outline:hover,
    .button--outline:active,
    .button--outline.button--active {
      --ifm-button-color: var(--ifm-font-color-base-inverse);
    }
.button--link {
    --ifm-button-background-color: transparent;
    --ifm-button-border-color: transparent;

    color: var(--ifm-link-color);
    -webkit-text-decoration: var(--ifm-link-decoration);
            text-decoration: var(--ifm-link-decoration);
  }
.button--link:hover,
    .button--link:active,
    .button--link..button--active {
      color: var(--ifm-link-hover-color);
      -webkit-text-decoration: var(--ifm-link-hover-decoration);
              text-decoration: var(--ifm-link-hover-decoration);
    }
.button.disabled,
  .button:disabled,
  .button[disabled] {
    opacity: 0.65;
    pointer-events: none;
  }
.button--sm {
    --ifm-button-size-multiplier: 0.8;
  }
.button--lg {
    --ifm-button-size-multiplier: 1.35;
  }
.button--block {
    display: block;
    width: 100%;
  }
.button.button--secondary {
    color: var(--ifm-color-gray-900);
  }
.button.button--secondary.button--outline:not(.button--active):not(:hover) {
      color: var(--ifm-font-color-base);
    }
.button--primary {
    --ifm-button-border-color: var(--ifm-color-primary);
  }
.button--primary:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-primary);
    }
.button--primary:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-primary-dark);
        --ifm-button-border-color: var(--ifm-color-primary-dark);
      }
.button--primary:active,
    .button--primary.button--active {
      --ifm-button-border-color: var(--ifm-color-primary-darker);
      --ifm-button-background-color: var(--ifm-color-primary-darker);

      background-color: var(
        --ifm-color-primary-darker
      );
      border-color: var(
        --ifm-color-primary-darker
      );
    }
.button--secondary {
    --ifm-button-border-color: var(--ifm-color-secondary);
  }
.button--secondary:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-secondary);
    }
.button--secondary:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-secondary-dark);
        --ifm-button-border-color: var(--ifm-color-secondary-dark);
      }
.button--secondary:active,
    .button--secondary.button--active {
      --ifm-button-border-color: var(--ifm-color-secondary-darker);
      --ifm-button-background-color: var(--ifm-color-secondary-darker);

      background-color: var(
        --ifm-color-secondary-darker
      );
      border-color: var(
        --ifm-color-secondary-darker
      );
    }
.button--success {
    --ifm-button-border-color: var(--ifm-color-success);
  }
.button--success:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-success);
    }
.button--success:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-success-dark);
        --ifm-button-border-color: var(--ifm-color-success-dark);
      }
.button--success:active,
    .button--success.button--active {
      --ifm-button-border-color: var(--ifm-color-success-darker);
      --ifm-button-background-color: var(--ifm-color-success-darker);

      background-color: var(
        --ifm-color-success-darker
      );
      border-color: var(
        --ifm-color-success-darker
      );
    }
.button--info {
    --ifm-button-border-color: var(--ifm-color-info);
  }
.button--info:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-info);
    }
.button--info:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-info-dark);
        --ifm-button-border-color: var(--ifm-color-info-dark);
      }
.button--info:active,
    .button--info.button--active {
      --ifm-button-border-color: var(--ifm-color-info-darker);
      --ifm-button-background-color: var(--ifm-color-info-darker);

      background-color: var(
        --ifm-color-info-darker
      );
      border-color: var(
        --ifm-color-info-darker
      );
    }
.button--warning {
    --ifm-button-border-color: var(--ifm-color-warning);
  }
.button--warning:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-warning);
    }
.button--warning:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-warning-dark);
        --ifm-button-border-color: var(--ifm-color-warning-dark);
      }
.button--warning:active,
    .button--warning.button--active {
      --ifm-button-border-color: var(--ifm-color-warning-darker);
      --ifm-button-background-color: var(--ifm-color-warning-darker);

      background-color: var(
        --ifm-color-warning-darker
      );
      border-color: var(
        --ifm-color-warning-darker
      );
    }
.button--danger {
    --ifm-button-border-color: var(--ifm-color-danger);
  }
.button--danger:not(.button--outline) {
      --ifm-button-background-color: var(--ifm-color-danger);
    }
.button--danger:not(.button--outline):hover {
        --ifm-button-background-color: var(--ifm-color-danger-dark);
        --ifm-button-border-color: var(--ifm-color-danger-dark);
      }
.button--danger:active,
    .button--danger.button--active {
      --ifm-button-border-color: var(--ifm-color-danger-darker);
      --ifm-button-background-color: var(--ifm-color-danger-darker);

      background-color: var(
        --ifm-color-danger-darker
      );
      border-color: var(
        --ifm-color-danger-darker
      );
    }
:root {
  --ifm-button-group-margin: 2px;
}
.button-group {
  display: inline-flex;
}
.button-group > .button:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: var(--ifm-button-group-margin);
    }
.button-group > .button:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
.button-group > .button--active {
      z-index: 1;
    }
.button-group--block {
    display: flex;
    justify-content: stretch;
  }
.button-group--block > .button {
      flex-grow: 1;
    }
:root {
  --ifm-card-background-color: var(--ifm-background-surface-color);
  --ifm-card-border-radius: calc(var(--ifm-global-radius) * 2);
  --ifm-card-horizontal-spacing: var(--ifm-global-spacing);
  --ifm-card-vertical-spacing: var(--ifm-global-spacing);
}
.card {
  background-color: var(--ifm-card-background-color);
  border-radius: var(--ifm-card-border-radius);
  box-shadow: var(--ifm-global-shadow-lw);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.card--full-height {
    height: 100%;
  }
.card__image {
    padding-top: var(--ifm-card-vertical-spacing);
  }
.card__image:first-child {
      padding-top: 0;
    }
.card__header,
  .card__body,
  .card__footer {
    padding: var(--ifm-card-vertical-spacing) var(--ifm-card-horizontal-spacing);
  }
.card__header:not(:last-child), .card__body:not(:last-child), .card__footer:not(:last-child) {
      padding-bottom: 0;
    }
.card__header > :last-child, .card__body > :last-child, .card__footer > :last-child {
      margin-bottom: 0;
    }
.card__footer {
    margin-top: auto;
  }
:root {
  --ifm-toc-border-color: var(--ifm-color-emphasis-300);
  --ifm-toc-link-color: var(--ifm-color-content-secondary);
  --ifm-toc-padding-left: 0.5rem;
  --ifm-toc-padding-vertical: 0.5rem;
}
.table-of-contents {
  font-size: 0.8rem;
  margin-bottom: 0;
  padding-bottom: var(--ifm-toc-padding-vertical);
  padding-top: var(--ifm-toc-padding-vertical);
}
.table-of-contents,
  .table-of-contents ul {
    list-style-type: none;
    padding-left: var(--ifm-toc-padding-left);
  }
.table-of-contents li {
    margin: var(--ifm-toc-padding-vertical);
  }
.table-of-contents__left-border {
    border-left-color: var(--ifm-toc-border-color);
    border-left-style: solid;
    border-left-width: 1px;
  }
.table-of-contents__link {
    color: var(--ifm-toc-link-color);
  }
.table-of-contents__link:hover,
    .table-of-contents__link:hover code,
    .table-of-contents__link--active,
    .table-of-contents__link--active code {
      color: var(--ifm-color-primary);
      text-decoration: none;
    }
.close {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  color: var(--ifm-color-black);
  cursor: pointer;
  float: right;
  font-size: 1.5rem;
  font-weight: var(--ifm-font-weight-bold);
  line-height: 1;
  opacity: 0.5;
  outline: 0;
  padding: 1rem;
}
.close:hover {
    opacity: 0.7;
  }
.close:focus {
    opacity: 0.8;
  }
:root {
  --ifm-dropdown-background-color: var(--ifm-background-surface-color);
  --ifm-dropdown-font-weight: var(--ifm-font-weight-semibold);
  --ifm-dropdown-link-color: var(--ifm-font-color-base);
  --ifm-dropdown-hover-background-color: var(--ifm-hover-overlay);
}
.dropdown {
  display: inline-flex;
  font-weight: var(--ifm-dropdown-font-weight);
  position: relative;
  vertical-align: top;
}
.dropdown--hoverable:hover .dropdown__menu, .dropdown--show .dropdown__menu {
      display: block;
    }
.dropdown--right .dropdown__menu {
      right: 0;
    }
.dropdown--nocaret .navbar__link:after {
    content: none !important;
  }
.dropdown__menu {
    background-color: var(--ifm-dropdown-background-color);
    border-radius: var(--ifm-global-radius);
    box-shadow: var(--ifm-global-shadow-md);
    display: none;
    min-width: 10rem;
    list-style: none;
    padding: 0.5rem;
    position: absolute;
    top: 100%;
    z-index: var(--ifm-z-index-dropdown);
  }
.dropdown__link {
    border-radius: 0.375rem;
    color: var(--ifm-dropdown-link-color);
    display: block;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
    white-space: nowrap;
  }
.dropdown__link:hover,
    .dropdown__link--active {
      background-color: var(--ifm-dropdown-hover-background-color);
      text-decoration: none;
      color: var(--ifm-dropdown-link-color);
    }
.dropdown__link--active,
    .dropdown__link--active:hover {
      --ifm-dropdown-link-color: var(--ifm-link-color);
    }
.dropdown > .navbar__link:after {
    border-color: currentColor;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
    content: '';
    display: inline-block;
    margin-left: 0.3em;
    vertical-align: 0.15em;
  }
:root {
  --ifm-footer-background-color: var(--ifm-color-emphasis-100);
  --ifm-footer-color: inherit;
  --ifm-footer-link-color: var(--ifm-color-emphasis-700);
  --ifm-footer-link-hover-color: var(--ifm-color-primary);
  --ifm-footer-link-horizontal-spacing: 0.5rem;
  --ifm-footer-padding-horizontal: calc(var(--ifm-spacing-horizontal) * 2);
  --ifm-footer-padding-vertical: calc(var(--ifm-spacing-vertical) * 2);
  --ifm-footer-title-color: inherit;
}
.footer {
  background-color: var(--ifm-footer-background-color);
  color: var(--ifm-footer-color);
  padding: var(--ifm-footer-padding-vertical)
    var(--ifm-footer-padding-horizontal);
  width: 100%;
}
.footer--dark {
    --ifm-footer-background-color: #303846;
    --ifm-footer-color: var(--ifm-footer-link-color);
    --ifm-footer-link-color: var(--ifm-color-secondary);
    --ifm-footer-title-color: var(--ifm-color-white);
  }
.footer__links {
    margin-bottom: 1rem;
  }
.footer__link-item {
    color: var(--ifm-footer-link-color);
    line-height: 2;
  }
.footer__link-item:hover {
      color: var(--ifm-footer-link-hover-color);
    }
.footer__link-separator {
    margin-left: var(--ifm-footer-link-horizontal-spacing);
    margin-right: var(--ifm-footer-link-horizontal-spacing);
  }
.footer__logo {
    margin-top: 1rem;
    max-width: 10rem;
  }
.footer__title {
    color: var(--ifm-footer-title-color);
  }
.footer__item {
    margin-top: 0;
  }
.footer__items {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
  }
@media (max-width: 996px) {
.footer {
    --ifm-footer-padding-horizontal: 0
}

    .footer__link-separator {
      display: none;
    }

    .footer__col {
      margin-bottom: calc(var(--ifm-spacing-vertical) * 3);
    }

    .footer__link-item {
      display: block;
    }
  }
[type='checkbox'] {
  padding: 0;
}
:root {
  --ifm-hero-background-color: var(--ifm-background-surface-color);
  --ifm-hero-text-color: var(--ifm-color-emphasis-800);
}
.hero {
  align-items: center;
  background-color: var(--ifm-hero-background-color);
  color: var(--ifm-hero-text-color);
  display: flex;
  padding: 4rem 2rem;
}
.hero--primary {
    --ifm-hero-background-color: var(--ifm-color-primary);
    --ifm-hero-text-color: var(--ifm-font-color-base-inverse);
  }
.hero--dark {
    --ifm-hero-background-color: #303846;
    --ifm-hero-text-color: var(--ifm-color-white);
  }
.hero__title {
    font-size: 3rem;
  }
.hero__subtitle {
    font-size: 1.5rem;
  }
@media (max-width: 996px) {
.hero {
    padding-left: 0;
    padding-right: 0
}
  }
:root {
  --ifm-menu-color: var(--ifm-color-emphasis-700);
  --ifm-menu-color-active: var(--ifm-color-primary);
  --ifm-menu-color-background-active: var(--ifm-hover-overlay);
  --ifm-menu-color-background-hover: var(--ifm-hover-overlay);
  --ifm-menu-link-padding-horizontal: 1rem;
  --ifm-menu-link-padding-vertical: 0.375rem;
  --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg alt="Arrow" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgba(0,0,0,0.5)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');
}
.menu {
  font-weight: var(--ifm-font-weight-semibold);
  overflow-x: hidden;
}
.menu__list {
    margin: 0;
    list-style-type: none;
    padding-left: 0;
    transition: height var(--ifm-transition-fast)
      cubic-bezier(0.08, 0.52, 0.52, 1);
  }
.menu__list .menu__list {
      margin-left: var(--ifm-menu-link-padding-horizontal);
    }
.menu__list-item {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
.menu__list-item--collapsed .menu__list {
        height: 0;
        overflow: hidden;
      }
.menu__list-item--collapsed .menu__link--sublist:after {
        transform: rotateZ(90deg);
      }
.menu__link {
    border-radius: 0.25rem;
    color: var(--ifm-menu-color);
    cursor: pointer;
    display: flex;
    line-height: 20px;
    justify-content: space-between;
    margin: 0;
    padding: var(--ifm-menu-link-padding-vertical)
      var(--ifm-menu-link-padding-horizontal);
  }
.menu__link:hover {
      text-decoration: none;
    }
.menu__link--sublist:after {
      background-image: var(--ifm-menu-link-sublist-icon);
      background-size: 2rem 2rem;
      background-position: center;
      content: ' ';
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      transition: transform var(--ifm-transition-fast) linear;
      transform: rotate(180deg);
    }
.menu__link:hover {
      background: var(--ifm-menu-color-background-hover);
      color: var(--ifm-menu-color);
    }
.menu__link--active {
      color: var(--ifm-menu-color-active);
    }
.menu__link--active:hover {
        color: var(--ifm-menu-color-active);
      }
.menu__link--active:not(.menu__link--sublist) {
        background: var(--ifm-menu-color-background-active);
      }
.menu--responsive .menu__button {
      bottom: 2rem;
      display: none;
      position: fixed;
      right: 1rem;
      z-index: var(--ifm-z-index-fixed);
    }
@media (max-width: 996px) {
.menu--responsive .menu__button {
        display: inherit
    }
      }
@media (max-width: 996px) {
      .menu--responsive .menu__list {
        display: none;
        opacity: 0;
      }
    }
.menu--show {
    -ms-scroll-chaining: none;
    background: var(--ifm-background-surface-color);
    bottom: 0;
    left: 0;
    overscroll-behavior: contain;
    padding: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--ifm-z-index-overlay);
  }
.menu--show .menu__list {
      display: inherit;
      opacity: 100;
      transition: opacity var(--ifm-transition-fast) linear;
    }
:root {
  --ifm-navbar-background-color: var(--ifm-background-surface-color);
  --ifm-navbar-height: 3.75rem;
  --ifm-navbar-item-padding-horizontal: 1rem;
  --ifm-navbar-item-padding-vertical: 0.25rem;
  --ifm-navbar-link-color: var(--ifm-font-color-base);
  --ifm-navbar-link-hover-color: var(--ifm-color-primary);
  --ifm-navbar-link-active-color: var(--ifm-link-color);
  --ifm-navbar-padding-horizontal: var(--ifm-spacing-horizontal);
  --ifm-navbar-padding-vertical: calc(var(--ifm-spacing-vertical) * 0.5);
  --ifm-navbar-shadow: var(--ifm-global-shadow-lw);
  --ifm-navbar-search-input-background-color: var(--ifm-color-emphasis-200);
  --ifm-navbar-search-input-color: var(--ifm-color-emphasis-800);
  --ifm-navbar-search-input-placeholder-color: var(--ifm-color-emphasis-500);
  --ifm-navbar-search-input-icon: url('data:image/svg+xml;utf8,<svg fill="currentColor" alt="Search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="16px" width="16px"><path d="M6.02945,10.20327a4.17382,4.17382,0,1,1,4.17382-4.17382A4.15609,4.15609,0,0,1,6.02945,10.20327Zm9.69195,4.2199L10.8989,9.59979A5.88021,5.88021,0,0,0,12.058,6.02856,6.00467,6.00467,0,1,0,9.59979,10.8989l4.82338,4.82338a.89729.89729,0,0,0,1.29912,0,.89749.89749,0,0,0-.00087-1.29909Z" /></svg>');
  --ifm-navbar-sidebar-width: 80vw;
}
.navbar {
  background-color: var(--ifm-navbar-background-color);
  box-shadow: var(--ifm-navbar-shadow);
  box-sizing: border-box;
  display: flex;
  height: var(--ifm-navbar-height);
  padding: var(--ifm-navbar-padding-vertical)
    var(--ifm-navbar-padding-horizontal);
  width: 100%;
}
.navbar > .container,
  .navbar > .container-fluid {
    display: flex;
  }
@media (max-width: 996px) {
.navbar > .container,
  .navbar > .container-fluid {
      padding: 0
  }
    }
.navbar--fixed-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: var(--ifm-z-index-fixed);
  }
.navbar__inner {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
.navbar__brand {
    align-items: center;
    color: var(--ifm-navbar-link-color);
    display: flex;
    font-weight: bold;
    height: 2rem;
    margin-right: 1rem;
  }
.navbar__brand:hover {
      text-decoration: none;
      color: inherit;
    }
.navbar__title {
    flex-shrink: 0;
    max-width: 100%;
  }
.navbar__toggle {
    cursor: pointer;
    display: none;
    margin-right: 0.5rem;
  }
@media (max-width: 996px) {
.navbar__toggle {
      display: inherit
  }
    }
.navbar__logo {
    height: 100%;
    margin-right: 0.5rem;
  }
.navbar__items {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
  }
.navbar__items--center {
      flex: 0 0 auto;
    }
.navbar__items--center .navbar__brand {
        margin: 0;
      }
.navbar__items--right {
      justify-content: flex-end;
    }
.navbar__item {
    display: inline-block;
    padding: var(--ifm-navbar-item-padding-vertical)
      var(--ifm-navbar-item-padding-horizontal);
  }
.navbar__item.dropdown {
      padding: 0;
    }
.navbar__item.dropdown .navbar__link {
        pointer-events: none;
      }
@media (max-width: 996px) {
.navbar__item {
      display: none
  }
    }
.navbar__link {
    color: var(--ifm-navbar-link-color);
    cursor: pointer;
    font-weight: var(--ifm-font-weight-semibold);
    padding: var(--ifm-navbar-item-padding-vertical)
      var(--ifm-navbar-item-padding-horizontal);
    text-decoration: none;
  }
.navbar__link:hover,
    .navbar__link--active {
      color: var(--ifm-navbar-link-hover-color);
      text-decoration: none;
    }
.navbar __brand {
    color: var(--ifm-navbar-link-color);
  }
.navbar--dark {
    --ifm-navbar-background-color: #303846;
    --ifm-navbar-link-color: var(--ifm-color-white);
    --ifm-navbar-link-hover-color: var(--ifm-color-primary);
    --ifm-navbar-search-input-background-color: rgba(255, 255, 255, 0.1);
    --ifm-navbar-search-input-color: var(--ifm-color-white);
    --ifm-navbar-search-input-placeholder-color: rgba(255, 255, 255, 0.5);
  }
.navbar--primary {
    --ifm-navbar-background-color: var(--ifm-color-primary);
    --ifm-navbar-link-hover-color: var(--ifm-color-white);
    --ifm-navbar-search-input-background-color: rgba(255, 255, 255, 0.1);
    --ifm-navbar-search-input-color: var(--ifm-color-emphasis-500);
    --ifm-navbar-search-input-placeholder-color: rgba(255, 255, 255, 0.5);
  }
.navbar__search {
    padding-left: var(--ifm-navbar-padding-horizontal);
  }
.navbar__search-input {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: var(--ifm-navbar-search-input-background-color);
      background-image: var(--ifm-navbar-search-input-icon);
      background-position-x: 0.75rem;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: 1rem 1rem;
      border-radius: 2rem;
      border-width: 0;
      cursor: text;
      color: var(--ifm-navbar-search-input-color);
      display: inline-block;
      font-size: 0.9rem;
      line-height: 2rem;
      outline: none;
      padding: 0 0.5rem 0 2.25rem;
      width: 12.5rem;
    }
.navbar__search-input::-webkit-input-placeholder {
        color: var(--ifm-navbar-search-input-placeholder-color);
      }
.navbar__search-input::-moz-placeholder {
        color: var(--ifm-navbar-search-input-placeholder-color);
      }
.navbar__search-input:-ms-input-placeholder {
        color: var(--ifm-navbar-search-input-placeholder-color);
      }
.navbar__search-input::-ms-input-placeholder {
        color: var(--ifm-navbar-search-input-placeholder-color);
      }
.navbar__search-input::placeholder {
        color: var(--ifm-navbar-search-input-placeholder-color);
      }
@media (max-width: 996px) {
.navbar__search-input {
        width: 9rem
    }
      }
.navbar-sidebar {
    background-color: var(--ifm-navbar-background-color);
    bottom: 0;
    box-shadow: var(--ifm-global-shadow-md);
    display: none;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform var(--ifm-transition-fast) ease;
    width: var(--ifm-navbar-sidebar-width);
    overflow: auto;
  }
.navbar-sidebar--show .navbar-sidebar {
        transform: translateX(0);
      }
.navbar-sidebar--show .navbar-sidebar__backdrop {
        display: block;
      }
@media (max-width: 996px) {
.navbar-sidebar {
      display: block
  }
    }
.navbar-sidebar__backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
      display: none;
      left: 0;
      right: 0;
      position: fixed;
      top: 0;
    }
.navbar-sidebar__brand {
      align-items: center;
      box-shadow: var(--ifm-navbar-shadow);
      display: flex;
      height: var(--ifm-navbar-height);
      padding: var(--ifm-navbar-padding-vertical)
        var(--ifm-navbar-padding-horizontal);
    }
.navbar-sidebar__items {
      padding: 0.5rem;
    }
:root {
  --ifm-pagination-border-radius: calc(
    var(--ifm-global-radius) * var(--ifm-pagination-size-multiplier)
  );
  --ifm-pagination-color-active: var(--ifm-color-primary);
  --ifm-pagination-font-size: 1rem;
  --ifm-pagination-item-active-background: var(--ifm-hover-overlay);
  --ifm-pagination-page-spacing: 0.0625rem;
  --ifm-pagination-padding-horizontal: calc(var(--ifm-spacing-horizontal) * 1);
  --ifm-pagination-padding-vertical: calc(var(--ifm-spacing-vertical) * 0.25);
  --ifm-pagination-size-multiplier: 1;
}
.pagination {
  font-size: var(--ifm-pagination-font-size);
  padding-left: 0;
}
.pagination--sm {
    --ifm-pagination-font-size: 0.8rem;
    --ifm-pagination-padding-horizontal: 0.8rem;
    --ifm-pagination-padding-vertical: 0.2rem;
  }
.pagination--lg {
    --ifm-pagination-font-size: 1.2rem;
    --ifm-pagination-padding-horizontal: 1.2rem;
    --ifm-pagination-padding-vertical: 0.3rem;
  }
.pagination__item {
    display: inline-block;
    list-style-type: none;
  }
.pagination__item:not(:first-child) {
      margin-left: var(--ifm-pagination-page-spacing);
    }
.pagination__item:not(:last-child) {
      margin-right: var(--ifm-pagination-page-spacing);
    }
.pagination__item > span {
      padding: var(--ifm-pagination-padding-vertical);
    }
.pagination__item--active .pagination__link {
        background: var(--ifm-pagination-item-active-background);
        color: var(--ifm-pagination-color-active);
      }
.pagination__item:not(.pagination__item--active):hover .pagination__link {
        background: var(--ifm-pagination-item-active-background);
        transition: background var(--ifm-transition-fast)
          cubic-bezier(0.08, 0.52, 0.52, 1);
      }
.pagination__item--disabled,
    .pagination__item[disabled] {
      opacity: 0.25;
      pointer-events: none;
    }
.pagination__link {
    border-radius: var(--ifm-pagination-border-radius);
    color: var(--ifm-font-color-base);
    display: inline-block;
    padding: var(--ifm-pagination-padding-vertical)
      var(--ifm-pagination-padding-horizontal);
  }
.pagination__link:hover {
      text-decoration: none;
    }
:root {
  --ifm-pagination-nav-border-radius: var(--ifm-global-radius);
  --ifm-pagination-nav-color-hover: var(--ifm-color-primary);
}
.pagination-nav {
  display: flex;
}
.pagination-nav__item {
    display: flex;
    flex-basis: 50%;
    flex-grow: 1;
    max-width: 50%;
  }
.pagination-nav__item--next {
      text-align: right;
    }
.pagination-nav__item + .pagination-nav__item {
      margin-left: var(--ifm-spacing-horizontal);
    }
.pagination-nav__link {
    border-color: var(--ifm-color-emphasis-300);
    border-radius: var(--ifm-pagination-nav-border-radius);
    border-style: solid;
    border-width: 1px;
    display: block;
    flex-grow: 1;
    padding: var(--ifm-global-spacing);
  }
.pagination-nav__link:hover {
      border-color: var(--ifm-pagination-nav-color-hover);
      text-decoration: none;
    }
.pagination-nav__label {
    font-size: var(--ifm-h4-font-size);
    line-height: var(--ifm-heading-line-height);
    font-weight: var(--ifm-heading-font-weight);
    margin-bottom: 0;
    margin-top: 0;
    word-break: break-word;
  }
.pagination-nav__sublabel {
    font-size: var(--ifm-h5-font-size);
    line-height: var(--ifm-heading-line-height);
    color: var(--ifm-color-content-secondary);
    font-weight: var(--ifm-font-weight-semibold);
    margin-bottom: 0.25rem;
  }
:root {
  --ifm-pills-color-active: var(--ifm-color-primary);
  --ifm-pills-color-background-active: var(--ifm-hover-overlay);
  --ifm-pills-spacing: 0.0625rem;
}
.pills {
  font-weight: var(--ifm-font-weight-bold);
  padding-left: 0;
}
.pills__item {
    border-radius: 0.5rem;
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
    padding: 0.25rem 1rem;
  }
.pills__item--active {
      background: var(--ifm-pills-color-background-active);
      color: var(--ifm-pills-color-active);
    }
.pills__item:not(.pills__item--active):hover {
      background-color: var(--ifm-pills-color-background-active);
      transition: background var(--ifm-transition-fast)
        cubic-bezier(0.08, 0.52, 0.52, 1);
    }
.pills__item:not(:first-child) {
      margin-left: var(--ifm-pills-spacing);
    }
.pills__item:not(:last-child) {
      margin-right: var(--ifm-pills-spacing);
    }
.pills__item + .pills__item {
      margin-top: 0;
    }
.pills--block {
    display: flex;
    justify-content: stretch;
  }
@media (max-width: 996px) {
.pills--block {
      flex-direction: column
  }
    }
.pills--block .pills__item {
      flex-grow: 1;
      text-align: center;
    }
@media (max-width: 996px) {
        .pills--block .pills__item:not(:first-child) {
          margin-top: var(--ifm-pills-spacing);
        }

        .pills--block .pills__item:not(:last-child) {
          margin-bottom: var(--ifm-pills-spacing);
        }
      }
:root {
  --ifm-tabs-color: var(--ifm-font-color-secondary);
  --ifm-tabs-color-active: var(--ifm-color-primary);
  --ifm-tabs-padding-horizontal: 1rem;
  --ifm-tabs-padding-vertical: 1rem;
  --ifm-tabs-spacing: 0.0625rem;
}
.tabs {
  display: flex;
  overflow-x: auto;
  color: var(--ifm-tabs-color);
  font-weight: var(--ifm-font-weight-bold);
  margin-bottom: 0;
  padding-left: 0;
}
.tabs__item {
    box-sizing: content-box;
    border-bottom: 3px solid transparent;
    border-radius: var(--ifm-global-radius);
    cursor: pointer;
    display: inline-flex;
    list-style-type: none;
    padding: var(--ifm-tabs-padding-vertical) var(--ifm-tabs-padding-horizontal);
    margin: 0;
  }
.tabs__item--active {
      border-bottom-color: var(--ifm-tabs-color-active);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: var(--ifm-tabs-color-active);
    }
.tabs__item:hover {
      background-color: var(--ifm-hover-overlay);
    }
.tabs--block {
    justify-content: stretch;
  }
@media (max-width: 996px) {
.tabs--block {
      flex-direction: column
  }
    }
.tabs--block .tabs__item {
      flex-grow: 1;
      justify-content: center;
    }
@media (max-width: 996px) {
        .tabs--block .tabs__item:not(:first-child) {
          margin-top: var(--ifm-tabs-spacing);
        }

        .tabs--block .tabs__item:not(:last-child) {
          margin-bottom: var(--ifm-tabs-spacing);
        }
      }
.shadow--lw {
    box-shadow: var(--ifm-global-shadow-lw) !important;
  }
.shadow--md {
    box-shadow: var(--ifm-global-shadow-md) !important;
  }
.shadow--tl {
    box-shadow: var(--ifm-global-shadow-tl) !important;
  }
.text--primary {
    color: var(--ifm-color-primary);
  }
.text--secondary {
    color: var(--ifm-color-secondary);
  }
.text--success {
    color: var(--ifm-color-success);
  }
.text--info {
    color: var(--ifm-color-info);
  }
.text--warning {
    color: var(--ifm-color-warning);
  }
.text--danger {
    color: var(--ifm-color-danger);
  }
.text--center {
    text-align: center;
  }
.text--left {
    text-align: left;
  }
.text--justify {
    text-align: justify;
  }
.text--right {
    text-align: right;
  }
.text--capitalize {
    text-transform: capitalize;
  }
.text--lowercase {
    text-transform: lowercase;
  }
.text--uppercase {
    text-transform: uppercase;
  }
.text--light {
    font-weight: var(--ifm-font-weight-light);
  }
.text--normal {
    font-weight: var(--ifm-font-weight-normal);
  }
.text--semibold {
    font-weight: var(--ifm-font-weight-semibold);
  }
.text--bold {
    font-weight: var(--ifm-font-weight-bold);
  }
.text--italic {
  font-style: italic;
}
.text--truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text--break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.text--no-decoration,
  .text--no-decoration:hover {
    text-decoration: none;
  }
html[data-theme='dark'] {
  --ifm-color-emphasis-0: var(--ifm-color-gray-1000);
  --ifm-color-emphasis-100: var(--ifm-color-gray-900);
  --ifm-color-emphasis-200: var(--ifm-color-gray-800);
  --ifm-color-emphasis-300: var(--ifm-color-gray-700);
  --ifm-color-emphasis-400: var(--ifm-color-gray-600);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-400);
  --ifm-color-emphasis-700: var(--ifm-color-gray-300);
  --ifm-color-emphasis-800: var(--ifm-color-gray-200);
  --ifm-color-emphasis-900: var(--ifm-color-gray-100);
  --ifm-color-emphasis-1000: var(--ifm-color-gray-0);

  --ifm-background-color: #18191a;
  --ifm-background-surface-color: #242526;

  --ifm-hover-overlay: rgba(255, 255, 255, 0.05);

  --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg alt="Arrow" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgba(255,255,255,0.6)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');

  --ifm-color-content-secondary: rgba(255, 255, 255, 1);
}
:root {
  --ifm-color-emphasis-100: var(--ifm-color-gray-900);
  --ifm-color-emphasis-200: var(--ifm-color-gray-800);
  --ifm-color-emphasis-300: var(--ifm-color-gray-700);
  --ifm-color-emphasis-400: var(--ifm-color-gray-600);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-400);
  --ifm-color-emphasis-700: var(--ifm-color-gray-300);
  --ifm-color-emphasis-800: var(--ifm-color-gray-200);
  --ifm-color-emphasis-900: var(--ifm-color-gray-100);

  --ifm-background-color: #121212;
  --ifm-background-surface-color: #1e2125;

  --ifm-hover-overlay: rgba(255, 255, 255, 0.05);
}
